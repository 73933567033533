import { useLayoutEffect } from 'react';
import useCTLocation from '../../hooks/useCTLocation';

export default function ScrollToTop() {
  const location = useCTLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}
