import { useTheme } from '@material-ui/core';
import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import ScrollToTop from '../../components/misc/ScrollToTop';
import LoadingPage from '../../components/page/LoadingPage';
import Consts from '../../consts/Consts';
import { getRoutes } from '../../consts/Routes';
import usePages from '../../hooks/usePages';
import NotFoundPage from '../../pages/error/NotFoundPage';
import WeDownPage from '../../pages/error/WeDownPage';
import AppContext from '../context/AppContext';
import CTSideBar from '../sidebar/CTSideBar';

const Main = ({ isNavOpen, setIsNavOpen }) => {
  const theme = useTheme();
  const { isMobile, categoryData, categoryError } = useContext(AppContext);
  // Consider moving below into context
  const pages = usePages();

  return (
    <main>
      {isNavOpen && (
        <CTSideBar
          isNavOpen={isNavOpen}
          setIsNavOpen={setIsNavOpen}
          pages={pages}
          isMobile={isMobile}
        />
      )}
      <section className={isMobile ? 'mobile' : Consts.EMPTY}>
        {categoryError ? (
          <WeDownPage />
        ) : (
          categoryData && (
            <Suspense fallback={<LoadingPage />}>
              <ScrollToTop />
              <Switch>
                {getRoutes(pages)}
                {/* If any of the above exact Routes don't match, this Route will send to the error page. */}
                <Route>
                  <NotFoundPage />
                </Route>
              </Switch>
            </Suspense>
          )
        )}
      </section>
      <style jsx>
        {`
          main {
            display: flex;
            min-height: calc(100vh - ${theme.header.height}px);
            box-sizing: border-box;
            position: relative;
          }
          section {
            left: ${theme.sideBar.width}px;
            position: relative;
            width: calc(100vw - ${theme.sideBar.width}px);
          }
          section.mobile {
            left: unset;
            position: unset;
            width: 100vw;
          }
        `}
      </style>
    </main>
  );
};
export default Main;
