import React from 'react';
import { ReactComponent as BrowserErrorIcon } from '../../assets/svg/browserError.svg';
import ErrorPage from './ErrorPage';

const NotFoundPage = () => {
  return (
    <ErrorPage
      title="Sorry, the page you are looking for does not exist."
      subTitle="Please check the URL"
      image={<BrowserErrorIcon />}
      includeImage
    ></ErrorPage>
  );
};

export default NotFoundPage;
