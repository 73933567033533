import React from 'react';
import useAppContext from '../../../hooks/useAppContext';

const CTFormTag = ({
  ctHalfWidth = false,
  children,
  noMarginBottom = false,
}) => {
  const { isMobile } = useAppContext();
  return (
    <div
      className={`ct-form-element ${
        ctHalfWidth && !isMobile ? 'half-width' : 'full-width'
      }`}
    >
      {children}
      <style jsx>
        {`
          .ct-form-element {
            margin-bottom: ${noMarginBottom ? '0px' : '16px'};
          }
          .full-width {
            width: 100%;
          }
          .half-width {
            width: calc(50% - 8px);
          }
        `}
      </style>
    </div>
  );
};

export default CTFormTag;
