import React from 'react';
import Consts from '../../consts/Consts';

const AspectRatioDiv = ({
  width = 16,
  height = 9,
  margin = '',
  padding = '',
  children,
}) => {
  return (
    <div className="container">
      <div className="element">{children}</div>
      <style jsx>
        {`
          .container {
            position: relative;
            height: 0;
            padding-bottom: ${(height * 100) / width}%;
            ${margin ? `margin : ${margin};` : Consts.EMPTY}
          }
          .element {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            ${padding ? `padding : ${padding};` : Consts.EMPTY}
          }
        `}
      </style>
    </div>
  );
};

export default AspectRatioDiv;
