import React from 'react';
import ErrorPage from './ErrorPage';
import LoginButton from '../../components/button/LoginButton';
import { ReactComponent as UserLoginIcon } from '../../assets/svg/userLogin.svg';
import HomePageButton from '../../components/button/HomePageButton';

const UnAuthenticatedPage = () => {
  return (
    <ErrorPage
      title="Please login to view this page."
      subTitle={
        <div>
          <LoginButton />
          <HomePageButton />
        </div>
      }
      image={<UserLoginIcon />}
      includeImage
    >
      <style jsx>
        {`
          div > :global(:first-child) {
            margin-right: 32px;
          }
        `}
      </style>
    </ErrorPage>
  );
};

export default UnAuthenticatedPage;
