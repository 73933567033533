import { CircularProgress } from '@material-ui/core';
import React from 'react';

const CTLoader = ({ label = 'Loading', hideLabel = false }) => {
  return (
    <div>
      <CircularProgress />
      {!hideLabel && <p>{label}</p>}
      <style jsx>{`
        div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: 16px;
        }
        p {
          margin-top: 8px;
        }
      `}</style>
    </div>
  );
};

export default CTLoader;
