const APIs = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  VIDEOS: 'videos',
  RECENT_VIDEOS: 'videos/recent',
  POPULAR_VIDEOS: 'videos/popular',
  CATEGORIES: 'categories',
  UNPUBLISHED_VIDEOS: 'videos/unpublished',
  REVIEW_VIDEOS: 'videos/review',
  ORG_VIDEOS: 'videos/org-videos',
  USER_VIDEOS: 'videos/user-videos',
  PREMIUM_VIDEOS: 'videos/premium-videos',
  MY_VIDEOS: 'videos/my-videos',
  ORIGINAL_STREAM: 'videos/stream/:id',
  STREAM: 'videos/stream/:id',
  VIDEO_DETAILS: 'videos/:id',
  VIDEO_VIEW: 'videos/view/:id',
  RELATED_VIDEOS: 'videos/related',
  APPROVE_VIDEO: 'videos/approval',
  BLOCK_VIDEO: 'videos/block',
  LIKE_VIDEO: 'videos/like',
  COMMENT_VIDEO: 'videos/comments',
  CATEGORY_VIDEOS: 'videos/category/:id',
  PLAYLISTS: 'playlists',
  ORG_PLAYLISTS: 'playlists/org',
  USER_PLAYLISTS: 'playlists/my',
  PLAYLIST_DETAILS: 'playlists/:id',
  PLAYLIST_VIDEOS: 'videos/playlist/:id',
  ADMIN_SEARCH_VIDEOS: 'videos/admin-search/:id',
  SEARCH_VIDEOS: 'videos/search/:id',
  REGISTER: 'auth/register',
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  SEND_VERIFICATION_EMAIL: 'auth/send-verification-email',
  VERIFY_EMAIL: 'auth/verify-email',
  REFRESH_TOKENS: 'auth/refresh-tokens',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  CHANGE_PASSWORD: 'users/change-password',
  USER_DETAILS: 'users/',
  UPLOAD_ORG_VIDEOS: 'videos/upload-org-video',
  SUBSCRIBE: 'users/subscribe',
  DONATE: 'users/donate',
  CHECK_SUBSCRIPTION: 'users/check-subscription',
  MANAGE_SUBSCRIPTION: 'users/manage-subscription',
  SYNC_SUBSCRIPTION: 'users/sync-subscription',
  ADMINS: 'users/admins',
  UPDATE_VIDEO_CATEGORY: 'videos/update-video-category',
  DELETE_VIDEO: 'videos/delete-video',
  UPLOAD_CHUNKS: 'videos/upload-chunks',
  UPLOAD_CHUNKS_ORG: 'videos/upload-chunks-org',
  SAVE_UPLOAD_CHUNKS_ORG: 'videos/new-org',
  SAVE_UPLOAD_CHUNKS: 'videos/new',
  VIDEO_EDIT: 'videos/edit-video',
  VIDEO_DETAILS_EDIT: 'videos/edit-video/:id',
  getAPIUrl: function (apiName, id) {
    if (apiName) {
      if (id) {
        return APIs.BASE_API_URL + apiName.replace(':id', id);
      }
      return APIs.BASE_API_URL + apiName;
    } else {
      const errorObject = {
        message: 'Please provide api name',
        error: 'API_NAME_MISSING',
      };
      throw errorObject;
    }
  },
};
export default APIs;
