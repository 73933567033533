import React from 'react';
import CTLoader from '../misc/CTLoader';
import PaddedPage from './PaddedPage';

const LoadingPage = () => {
  return (
    <PaddedPage>
      <div>
        <CTLoader />
      </div>
      <style jsx>
        {`
          div {
            height: 90vh;
            display: flex;
            justify-content: center;
          }
        `}
      </style>
    </PaddedPage>
  );
};

export default LoadingPage;
