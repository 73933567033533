import {
  Home,
  Explore,
  PlaylistPlay,
  CloudUpload,
  CardMembership,
  SupervisorAccount,
  ModeComment,
  AttachMoney,
  OndemandVideo,
  Person,
  Publish,
  PersonAdd,
  List,
  RateReview,
} from '@material-ui/icons';
import Consts from './Consts';
import Routes from './Routes';

// If sideBarLabel, sideBarIcon is missing, it's left out of the sideBar
// Order of objects that have a sideBarLabel, sideBarIcon, controls the order in the sideBar
// path should the relative path and name from /pages
// If disabled, the route won't be created, nor will it be added to the left nav

const Pages = (user, isAdminMode) => {
  const role = user && user.role;
  const isSuperAdmin = role === Consts.ROLES.SUPER_ADMIN;
  const isAdmin = role === Consts.ROLES.ADMIN || isSuperAdmin;
  const isPremiumUser = role === Consts.ROLES.PREMIUM_USER || isAdmin;
  const isUser = role === Consts.ROLES.USER || isAdmin || isPremiumUser;

  const superAdminRoute = getPageAuthFields(
    !isSuperAdmin,
    !isSuperAdmin,
    !isSuperAdmin,
    isSuperAdmin && isAdminMode
  );
  const adminRoute = getPageAuthFields(
    !isAdmin,
    !isAdmin,
    !isAdmin,
    isAdmin && isAdminMode
  );
  const anonymousRoute = getPageAuthFields(
    false,
    false,
    false,
    !(isAdmin && isAdminMode),
    role && Routes.HOMEPAGE
  );
  const commonRoute = getPageAuthFields(
    false,
    false,
    false,
    !(isAdmin && isAdminMode)
  );
  const userRoute = getPageAuthFields(
    !isUser,
    false,
    false,
    isUser && !(isAdmin && isAdminMode)
  );
  // FUTURE
  // const premiumUserRoute = getPageAuthFields(
  //   false,
  //   !isPremiumUser,
  //   false,
  //   !(isAdmin && isAdminMode)
  // );

  return [
    {
      path: 'home/Home',
      url: Routes.HOMEPAGE,
      sideBarLabel: 'Home',
      sideBarIcon: <Home />,
      ...commonRoute,
    },
    {
      path: isPremiumUser
        ? 'membership/Membership'
        : 'membership/JoinMembership',
      url: Routes.MEMBERSHIP,
      sideBarLabel: 'Membership',
      sideBarIcon: <CardMembership />,
      ...commonRoute,
    },
    {
      path: 'category/Categories',
      url: Routes.CATEGORIES,
      sideBarLabel: 'Explore',
      sideBarIcon: <Explore />,
      unAuthorized: false,
      ...commonRoute,
    },
    {
      path: 'category/CategoryVideos',
      url: Routes.CATEGORY_VIDEOS,
      ...commonRoute,
    },
    {
      path: 'playlist/PlaylistsList',
      url: Routes.PLAYLISTS,
      sideBarLabel: 'Playlists',
      sideBarIcon: <PlaylistPlay />,
      ...commonRoute,
    },
    {
      path: 'playlist/PlaylistVideos',
      url: Routes.PLAYLIST_VIDEOS,
      ...commonRoute,
    },
    {
      path: 'search/SearchPage',
      url: Routes.SEARCH,
      ...commonRoute,
    },
    {
      path: 'upload/UploadVideo',
      url: Routes.UPLOAD_USER_VIDEO,
      sideBarLabel: 'Upload',
      sideBarIcon: <CloudUpload />,
      ...userRoute,
    },
    {
      path: 'account/UserProfile',
      url: Routes.MY_PROFILE,
      ...userRoute,
    },
    {
      path: 'myvideos/MyVideosPage',
      url: Routes.MY_VIDEOS,
      sideBarLabel: 'My Videos',
      sideBarIcon: <OndemandVideo />,
      ...userRoute,
    },
    {
      path: 'video/VideoEdit',
      url: Routes.EDIT_VIDEO,
      sideBarLabel: 'Edit Video',
      ...userRoute,
    },
    {
      path: 'video/VideoEdit',
      url: Routes.ADMIN_EDIT_VIDEO,
      sideBarLabel: 'Video Edit',
      ...adminRoute,
    },
    {
      path: 'video/VideoPage',
      url: Routes.VIDEO,
      ...commonRoute,
    },
    {
      path: 'video/VideoPage',
      url: Routes.ADMIN_VIDEO,
      ...adminRoute,
    },
    {
      path: 'review/ReviewVideos',
      url: Routes.REVIEW_VIDEOS,
      sideBarLabel: 'Review Videos',
      sideBarIcon: <RateReview />,
      ...adminRoute,
    },
    {
      path: 'unpublished/UnpublishedVideos',
      url: Routes.UNPUBLISHED_VIDEOS,
      sideBarLabel: 'Unpublished Videos',
      sideBarIcon: <Publish />,
      ...adminRoute,
    },
    {
      path: 'org/OrgVideos',
      url: Routes.ORG_VIDEOS,
      sideBarLabel: 'Org Videos',
      sideBarIcon: <SupervisorAccount />,
      ...adminRoute,
    },
    {
      path: 'user/UserVideos',
      url: Routes.USER_VIDEOS,
      sideBarLabel: 'User Videos',
      sideBarIcon: <Person />,
      ...adminRoute,
    },
    {
      path: 'upload/UploadVideo',
      url: Routes.UPLOAD_ORG_VIDEO,
      sideBarLabel: 'Upload Org Videos',
      sideBarIcon: <CloudUpload />,
      ...adminRoute,
    },
    {
      path: 'category/Categories',
      url: Routes.ADMIN_CATEGORIES,
      sideBarLabel: 'Update Categories',
      sideBarIcon: <List />,
      ...adminRoute,
    },
    {
      path: 'category/AddCategory',
      url: Routes.ADMIN_ADD_CATEGORY,
      ...adminRoute,
    },
    {
      path: 'search/SearchPage',
      url: Routes.ADMIN_SEARCH,
      ...adminRoute,
    },
    {
      path: 'category/AddCategory',
      url: Routes.ADMIN_UPDATE_CATEGORY,
      ...adminRoute,
    },
    {
      path: 'admin/AdminDetailsPage',
      url: Routes.ADMINS,
      sideBarLabel: 'Admins',
      sideBarIcon: <PersonAdd />,
      ...superAdminRoute,
    },
    {
      path: 'about/AboutUs',
      url: Routes.ABOUT_US,
      sideBarLabel: 'About Us',
      sideBarIcon: <ModeComment />,
      ...commonRoute,
    },
    {
      path: 'support/SupportUs',
      url: Routes.SUPPORT_US,
      sideBarLabel: 'Support Us',
      sideBarIcon: <AttachMoney />,
      ...commonRoute,
    },
    {
      path: 'about/TermsNConditions',
      url: Routes.TERMS_CONDITIONS,
      ...commonRoute,
    },
    {
      path: 'about/PrivacyPolicy',
      url: Routes.PRIVACY_POLICY,
      ...commonRoute,
    },
    {
      path: 'support/DonateResultPage',
      url: Routes.DONATE,
      ...commonRoute,
    },
    {
      path: 'account/Register',
      url: Routes.REGISTER,
      ...anonymousRoute,
    },
    {
      path: 'account/Login',
      url: Routes.LOGIN,
      ...commonRoute,
    },
    {
      path: 'account/EmailVerification',
      url: Routes.VERIFY_EMAIL,
      ...anonymousRoute,
    },
    {
      path: 'account/EmailConfirmation',
      url: Routes.CONFIRM_EMAIL,
      ...anonymousRoute,
    },
    {
      path: 'account/ForgotPassword',
      url: Routes.FORGOT_PASSWORD,
      ...anonymousRoute,
    },
    {
      path: 'account/ResetPassword',
      url: Routes.RESET_PASSWORD,
      ...anonymousRoute,
    },
    {
      path: 'membership/MembershipSuccess',
      url: Routes.MEMBERSHIP_SUCCESS,
      ...userRoute,
    },
    {
      path: 'membership/MembershipFailure',
      url: Routes.MEMBERSHIP_FAILURE,
      ...userRoute,
    },
    {
      path: 'error/UnAuthorizedPage',
      url: Routes.ACCESS_DENIED,
      ...commonRoute,
    },
  ];
};

const getPageAuthFields = (
  unAuthenticated,
  unAuthorized,
  disabled = false,
  showInSideBar = true,
  redirect = Consts.EMPTY
) => {
  return {
    unAuthenticated,
    unAuthorized,
    disabled,
    showInSideBar,
    redirect,
  };
};

export default Pages;
