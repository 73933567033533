import { useTheme } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import React from 'react';

const CTFormErrorText = ({ name }) => {
  const theme = useTheme();
  return (
    <div className="Mui-error MuiFormHelperText-root MuiFormHelperText-contained">
      <ErrorMessage name={name} />
      <style jsx>
        {`
          .MuiFormHelperText-root.Mui-error {
            color: ${theme.color.errorRed};
          }
        `}
      </style>
    </div>
  );
};
export default CTFormErrorText;
