import React from 'react';
import useAppContext from '../../hooks/useAppContext';
import { getImageURL, hasPremiumAccess } from '../../utils/utils';
import AppModeButton from '../button/AppModeButton';
import JoinMembershipButton from '../button/JoinMembershipButton';
import LogoutButton from '../button/LogoutButton';
import MyProfileButton from '../button/MyProfileButton';
import CTAvatar from '../user/CTAvatar';
import MenuComponent from './MenuComponent';

const ProfileMenu = () => {
  const { user, isMobile, isMobileOrTablet } = useAppContext();
  return (
    <div className="profile-menu">
      <MenuComponent
        menuButton={
          <CTAvatar
            name={user.firstName}
            variant="large"
            src={user.profileImage && getImageURL(user.profileImage)}
          />
        }
      >
        <div>
          <CTAvatar
            name={user.firstName}
            variant="ultra-large"
            src={user.profileImage && getImageURL(user.profileImage)}
          />
        </div>
        <div>
          <MyProfileButton />
        </div>
        {isMobile && (
          <div>
            <AppModeButton />
          </div>
        )}
        {isMobileOrTablet && !hasPremiumAccess(user) && (
          <div>
            <JoinMembershipButton />
          </div>
        )}
        <div>
          <LogoutButton />
        </div>
      </MenuComponent>
    </div>
  );
};

export default ProfileMenu;
