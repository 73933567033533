import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

const useCTFormikState = (name, ctState, ctSetState) => {
  const [field, , helpers] = useField(name);
  const condition = ctSetState && field.value;
  const { validateForm } = useFormikContext();
  useEffect(() => {
    if (ctSetState) {
      ctSetState(field.value);
    }
  }, [condition]);

  useEffect(() => {
    if (ctState !== null && ctState !== undefined && ctState !== field.value) {
      helpers.setValue(ctState);
    }
  }, [ctState]);

  useEffect(() => {
    validateForm();
  }, [field.value]);
};

export default useCTFormikState;
