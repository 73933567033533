/* eslint-disable no-prototype-builtins */
import { Form, Formik } from 'formik';
import { Close, Done } from '@material-ui/icons';
import React from 'react';
import * as Yup from 'yup';
import Consts from '../../consts/Consts';
import CTButton from '../button/CTButton';
import CTFormMeta from './core/CTFormMeta';
import CTFormMetaLabel from './core/CTFormMetaLabel';

const CTForm = ({
  children,
  dynamicChildren,
  onSubmit,
  onClose,
  fieldsToValidateOnLoad = {}, // Sets internal formik initialTouched for the field(s), so an error is shown without the user needing to click out of the field.
  showSubmitButton = true,
  showSubmitIcon = false,
  showCloseButton = false,
  submitLabel = 'Submit',
  sideBySide = false,
  validateOnBlur = true,
  validateOnChange = true,
  innerRef,
  submitLoading,
}) => {
  const initialValues = {};
  const initialErrors = {};
  const yupValues = {};

  const getNameError = name => {
    return `Error: CTForm children components must all define a unique name prop via defaultProps or inline attribute. name: ${
      name || 'empty/null'
    }`;
  };

  const addValues = children => {
    React.Children.forEach(children, child => {
      // Check if child is defined, since it can be null or empty string from ?: or && in JSX
      if (child && child.props) {
        // Check if it's a CTFormMeta element, if so skip it, since it's not a true control.
        // Might need below later
        if (child.type !== CTFormMeta && child.type !== CTFormMetaLabel) {
          if (
            child.props.children &&
            String(child.type) === 'Symbol(react.fragment)'
          ) {
            // If the child is a React.Fragment, pass it to addValues to loop over its children.
            addValues(child.props.children);
          } else if (!child.props.name) {
            // Throw an error that all must have a name prop
            throw new Error(getNameError(child.props.name));
          } else if (initialValues.hasOwnProperty(child.props.name)) {
            // Throw an error that all names must be unique.
            throw new Error(getNameError(child.props.name));
          } else if (
            !child.props.initialValue &&
            child.props.initialValue === undefined
          ) {
            // Throw an error to force initialValue to be defined or EMPTY, since it's required for <Formik>.
            throw new Error(
              `Error: CTForm children components must define initialValue via defaultProps or inline attribute. name: ${child.props.name}`
            );
          } else {
            initialValues[child.props.name] = child.props.initialValue;
            const yup = child.props.yup;
            if (yup) {
              yupValues[child.props.name] = yup;
              const isRequired =
                yup._exclusive && Boolean(yup._exclusive.required);

              // In order to start submit disabled, we need to provide a list of fields that have an error to start.
              // We do need to exlude those that start with an initialValue, so they aren't considered to have an error.
              if (isRequired) {
                if (child.props.initialValue === Consts.EMPTY)
                  initialErrors[child.props.name] = true;
              }
            }
          }
        }
      }
    });
  };

  const addDynamicValues = childList => {
    childList.forEach(child => {
      // Once the component is added, skip its dynamic value.
      if (initialValues[child.props.name] === undefined) {
        if (!child.props.name) {
          // Throw an error that all must have a name prop
          throw new Error(getNameError(child.props.name));
        } else if (initialValues.hasOwnProperty(child.props.name)) {
          // Throw an error that all names must be unique.
          throw new Error(getNameError(child.props.name));
        } else if (
          !child.props.initialValue &&
          child.props.initialValue === undefined
        ) {
          // Throw an error to force initialValue to be defined or EMPTY, since it's required for <Formik>.
          throw new Error(
            `Error: CTForm dynamicChildren objects must define initialValue via defaultProps or second argument to create. name: ${child.props.name}`
          );
        } else {
          initialValues[child.props.name] = child.props.initialValue;
        }
      }
    });
  };

  // Loop over the children elements and capture their initial value and yup value
  addValues(children);

  // Loop over the dynamic children elements and capture their initial value. yup will be captured only once it's in the DOM.
  if (dynamicChildren) addDynamicValues(dynamicChildren);

  // Yup validationSchema
  const validationSchema = Yup.object().shape(yupValues);
  return (
    <>
      <Formik
        innerRef={innerRef}
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        onSubmit={async formikValues => {
          return onSubmit(formikValues);
        }}
        initialTouched={fieldsToValidateOnLoad}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
      >
        {({ isValid, isSubmitting, values }) => {
          return (
            <>
              <Form
                className={`mtForm ${
                  sideBySide ? 'side-by-side' : Consts.EMPTY
                }`}
              >
                {children}
                {showSubmitButton && (
                  <div className="submit-container">
                    <CTButton
                      disabled={
                        // (validateOnBlur && validateOnChange && !isValid) ||
                        isSubmitting || submitLoading
                      }
                      loading={submitLoading}
                      type="submit"
                    >
                      {showSubmitIcon && <Done fontSize="small" />}
                      {submitLabel}
                    </CTButton>
                  </div>
                )}
                {showCloseButton && (
                  <div className="submit-container close-container">
                    <CTButton type="button" onClick={onClose}>
                      <Close fontSize="small" />
                    </CTButton>
                  </div>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
      <style jsx>
        {`
          :global(.mtForm:not(.side-by-side)) .submit-container {
            width: 100%;
            text-align: center;
          }
          :global(.mtForm:not(.side-by-side)) {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          :global(.mtForm) {
            display: flex;
          }
        `}
      </style>
    </>
  );
};

export default CTForm;

// Pass the component that has defaultProps, optionally pass {name, initialValue, yup} overrides using the second argument
CTForm.createDynamicChild = (
  MTFormComponent,
  { name, initialValue, yup } = {}
) => {
  const defaultProps = MTFormComponent.defaultProps;
  return {
    props: {
      name: name || defaultProps.name,
      initialValue: initialValue || defaultProps.initialValue,
      yup: yup || defaultProps.yup,
    },
  };
};
