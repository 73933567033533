import { Search } from '@material-ui/icons';
import React from 'react';
import Routes from '../../consts/Routes';
import useAppContext from '../../hooks/useAppContext';
import useCTHistory from '../../hooks/useCTHistory';
import CTHeaderSearch from '../form/controls/CTHeaderSearch';
import CTForm from '../form/CTForm';

const CTHeaderSearchForm = () => {
  const history = useCTHistory();
  const { isAdminMode } = useAppContext();
  const onSubmit = formikValues => {
    if (formikValues.search) {
      history.push(
        isAdminMode ? Routes.ADMIN_SEARCH : Routes.SEARCH,
        formikValues.search
      );
    }
  };
  return (
    <div className="header-search">
      <CTForm
        sideBySide
        submitLabel={<Search />}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        <CTHeaderSearch />
      </CTForm>
      <style jsx>
        {`
          .header-search :global(.MuiInputBase-root) {
            height: 36px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          .header-search :global(.MuiButton-root) {
            height: 36px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
          .header-search :global(.ct-form-item) {
            margin-bottom: 0px;
          }
          .header-search {
            width: 100%;
            padding-left: 16px;
          }
        `}
      </style>
    </div>
  );
};

export default CTHeaderSearchForm;
