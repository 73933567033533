import React from 'react';
import APIs from '../../consts/APIs';
import Consts from '../../consts/Consts';
import useAppContext from '../../hooks/useAppContext';
import useCTAxios from '../../hooks/useCTAxios';
import { loadCookie } from '../../utils/cookieUtils';
import CTButton from './CTButton';

const LogoutButton = () => {
  const { setUser } = useAppContext();
  const clearUser = () => {
    setUser(null);
  };
  const [logout, { loading }] = useCTAxios(
    { url: APIs.getAPIUrl(APIs.LOGOUT), method: 'post' },
    clearUser,
    clearUser
  );
  const onClick = () => {
    const refreshToken = loadCookie(Consts.TOKEN_TYPES.REFRESH);
    logout({ refreshToken });
  };
  return (
    <CTButton onClick={onClick} loading={loading}>
      Log out
    </CTButton>
  );
};

export default LogoutButton;
