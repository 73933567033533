import React from 'react';
import Consts from '../../../consts/Consts';
import CTTextField from '../core/CTTextField';

const label = 'Search';
const CTHeaderSearch = ({ name, yup, initialValue, label }) => {
  return (
    <CTTextField
      name={name}
      placeholder="Search"
      initialValue={initialValue}
      yup={yup}
      noMarginBottom
    />
  );
};

CTHeaderSearch.defaultProps = {
  name: 'search',
  initialValue: Consts.EMPTY,
  label: label,
};

export default CTHeaderSearch;
