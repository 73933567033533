import { decode } from 'jsonwebtoken';
import APIs from '../consts/APIs';
import Consts from '../consts/Consts';

export const dedupArr = (arr, key) => {
  const ids = arr.map(o => o[key]);
  const filtered = arr.filter(
    ({ [key]: curKey }, index) => !ids.includes(curKey, index + 1)
  );
  return filtered;
};

export const objectToFormData = obj => {
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key]);
  });
  return formData;
};

export const decodeToken = token => {
  const returnData = { payload: null };
  try {
    const decodedToken = decode(token);
    const now = new Date();
    returnData.isExpired = now.getTime() > decodedToken.exp * 1000;
    if (!returnData.isExpired) {
      returnData.payload = decodedToken.data;
    }
  } catch (err) {
    // DO NOTHING, AS We need to return empty payload
  }
  return returnData;
};

export const bufferToBase64 = (binary, contentType) => {
  return `data:${contentType};base64,${Buffer.from(binary).toString('base64')}`;
};

export const bufferToFile = (binary, contentType, fileName) => {
  // If need to generate blob, Buffer.from is mandatory
  const bstr = atob(Buffer.from(binary).toString('base64'));
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: contentType });
};

export const getVideoMeta = (video, includeThumbnail = true) => {
  const ownerName = video.isOrgVideo
    ? process.env.REACT_APP_ORG_NAME
    : video.ownerName;
  const ownerImage = video.isOrgVideo
    ? `${process.env.PUBLIC_URL}/logo192.png`
    : video.ownerImage
    ? getImageURL(video.ownerImage)
    : null;
  const thumbnailImage = includeThumbnail
    ? getImageURL(video.thumbnailImage)
    : null;
  return { ownerName, ownerImage, thumbnailImage };
};

const getVideoUrl = (
  apiUrl,
  fileName,
  {
    isOrgVideo = false,
    isPremiumVideo = false,
    originalVideoLocation = '',
    streamToken = '',
  }
) => {
  return `${APIs.getAPIUrl(
    apiUrl,
    fileName
  )}?isOrg=${isOrgVideo}&isPremium=${isPremiumVideo}${
    originalVideoLocation ? `&isRaw=true` : ``
  }${streamToken ? `&streamToken=${streamToken}` : ``}`;
};

export const getVideoSources = videoDetails => {
  const src = [];
  let url;
  if (videoDetails) {
    if (videoDetails.originalVideoLocation) {
      url = getVideoUrl(
        APIs.ORIGINAL_STREAM,
        videoDetails.originalVideoLocation,
        videoDetails
      );
      src.push({ src: url, type: 'video/mp4' });
    } else {
      if (videoDetails.highResLocation) {
        url = getVideoUrl(
          APIs.ORIGINAL_STREAM,
          videoDetails.highResLocation,
          videoDetails
        );
        src.push({ src: url, type: 'video/mp4', size: 1080 });
      }
      if (videoDetails.medResLocation) {
        url = getVideoUrl(
          APIs.ORIGINAL_STREAM,
          videoDetails.medResLocation,
          videoDetails
        );
        src.push({ src: url, type: 'video/mp4', size: 720 });
      }
      if (videoDetails.lowResLocation) {
        url = getVideoUrl(
          APIs.ORIGINAL_STREAM,
          videoDetails.lowResLocation,
          videoDetails
        );
        src.push({ src: url, type: 'video/mp4', size: 480 });
      }
    }
  }
  return src;
};

export const getHistoryMeta = (params, id) => {
  const meta = { params };
  if (typeof params === 'string') {
    meta.route = params;
    if (id) {
      meta.params = { pathname: updateRouteWithId(params, id) };
    } else {
      meta.params = { pathname: params };
    }
  } else if (typeof params === 'object' && params.pathname) {
    meta.route = params.pathname;
    if (id) {
      meta.params.pathname = updateRouteWithId(params.pathname, id);
    }
  } else if (Array.isArray(params) && params.length > 0) {
    meta.route = params[0];
    meta.params = {
      pathname: params[1] ? updateRouteWithId(params[0], params[1]) : params[0],
    };
  }
  return meta;
};

const updateRouteWithId = (api, id) => {
  return api.replace(':id', id);
};

export const hasAdminAccess = user => {
  return (
    user &&
    (user.role === Consts.ROLES.ADMIN || user.role === Consts.ROLES.SUPER_ADMIN)
  );
};

export const hasPremiumAccess = user => {
  return (
    user &&
    (user.role === Consts.ROLES.PREMIUM_USER ||
      user.role === Consts.ROLES.ADMIN ||
      user.role === Consts.ROLES.SUPER_ADMIN)
  );
};

export const getImageURL = fileName => {
  return `${process.env.REACT_APP_STATIC_ASSETS_URL}${fileName}`;
};

export const getMimeType = filename => {
  const ext = filename.split('.').pop();
  const mimeTypes = {
    flv: 'video/x-flv',
    mp4: 'video/mp4',
    m3u8: 'application/x-mpegURL',
    ts: 'video/MP2T',
    '3gp': 'video/3gpp',
    mov: 'video/quicktime',
    avi: 'video/x-msvideo',
    wmv: 'video/x-ms-wmv',
    ogg: 'video/ogg',
    ogv: 'video/ogg',
  };

  if (Object.keys(mimeTypes).indexOf(ext) !== -1) {
    return mimeTypes[ext];
  }
  return false;
};
