import { FormLabel } from '@material-ui/core';
import React from 'react';
import CTFormMeta from './CTFormMeta';

const CTFormMetaLabel = ({ children }) => {
  return (
    <CTFormMeta>
      <FormLabel>{children}</FormLabel>
    </CTFormMeta>
  );
};

export default CTFormMetaLabel;
