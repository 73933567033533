import React from 'react';
import Consts from '../../consts/Consts';
import CTButton from './CTButton';

// FUTURE: Combine with CTButton
const CTColoredButton = ({ bgColor, textColor, ...otherProps }) => {
  return (
    <span
      className={`${bgColor ? 'bg-colored' : Consts.EMPTY} ${
        textColor ? 'text-colored' : Consts.EMPTY
      }`}
    >
      <CTButton {...otherProps} />
      <style jsx>
        {`
          span.bg-colored :global(.MuiButton-root) {
            background-color: ${bgColor};
          }
          span.text-colored :global(.MuiButton-root) {
            color: ${textColor};
          }
        `}
      </style>
    </span>
  );
};

export default CTColoredButton;
