import { ClickAwayListener, Drawer, useTheme } from '@material-ui/core';
import React from 'react';
import CTSideBarItem from './CTSideBarItem';
const CTSideBar = ({ pages, isNavOpen, setIsNavOpen, isMobile }) => {
  const theme = useTheme();
  const closeNav = e => {
    if (isMobile && isNavOpen) {
      e.preventDefault();
      setIsNavOpen(false);
    }
  };
  return (
    <div>
      <ClickAwayListener onClickAway={closeNav}>
        <Drawer variant="permanent">
          {pages.map(page => {
            return (
              page.showInSideBar &&
              page.sideBarLabel &&
              page.sideBarIcon && (
                <CTSideBarItem
                  key={page.url}
                  url={page.url}
                  label={page.sideBarLabel}
                  icon={page.sideBarIcon}
                  setIsNavOpen={setIsNavOpen}
                />
              )
            );
          })}
        </Drawer>
      </ClickAwayListener>
      <style jsx>
        {`
          div :global(.MuiDrawer-paper) {
            top: inherit;
            width: ${theme.sideBar.width}px;
          }
          div :global(.MuiDrawer-paperAnchorDockedLeft) {
            border-right: unset;
          }
        `}
      </style>
    </div>
  );
};
export default CTSideBar;
