import React from 'react';
import Consts from '../../consts/Consts';
import useCTHistory from '../../hooks/useCTHistory';
import { getHistoryMeta } from '../../utils/utils';

// FUTURE: rename to CTRedirect
const CTRedirectCard = ({
  pushParams,
  children,
  isSpan,
  color,
  openInNewTab = false,
}) => {
  const history = useCTHistory();
  const onClick = () => {
    if (openInNewTab) {
      window.open(pushParams, 'blank');
    } else {
      history.push(pushParams);
    }
  };
  const CustomTag = isSpan ? 'span' : 'div';
  const {
    params: { pathname },
  } = getHistoryMeta(pushParams);

  return (
    <a
      href={pathname}
      onClick={e => {
        e.preventDefault();
      }}
    >
      <CustomTag className="redirect-card" onClick={onClick}>
        {children}
      </CustomTag>
      <style jsx>{`
        .redirect-card {
          cursor: pointer;
          ${color ? `color: ${color}` : Consts.EMPTY}
        }

        a {
          text-decoration: none;
          color: inherit;
          cursor: auto;
          ${!isSpan ? 'display: block;' : Consts.EMPTY}
        }
      `}</style>
    </a>
  );
};

export default CTRedirectCard;
