import React from 'react';
import AspectRatioDiv from '../layout/AspectRatioDiv';

const CTImage = ({ src, children, width, height }) => {
  return (
    <AspectRatioDiv width={width} height={height}>
      <section className="image-container">
        <img
          className="image"
          loading="lazy"
          // FUTURE: Check MT base64 to blob
          // https://stackoverflow.com/questions/57699628/how-to-convert-a-file-buffer-to-img-tag-src
          src={src}
        ></img>
        {children}
      </section>
      <style jsx>{`
        .image {
          width: 100%;
          height: 100%;
        }
        .image-container {
          position: relative;
          height: 100%;
        }
      `}</style>
    </AspectRatioDiv>
  );
};

export default CTImage;
