import React from 'react';
import Page from './Page';

const PaddedPage = ({ children, maxWidth = '960px', ...pageProps }) => {
  return (
    <Page {...pageProps}>
      <div className="content-container">{children}</div>
      <style jsx>
        {`
          .content-container {
            width: 100%;
            min-width: 280px;
            max-width: ${maxWidth};
            margin: 0 auto;
          }
        `}
      </style>
    </Page>
  );
};

export default PaddedPage;
