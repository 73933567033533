import { useEffect } from 'react';

const useCTDocumentTitle = documentTitle => {
  useEffect(() => {
    document.title = documentTitle
      ? `${documentTitle} - ${process.env.REACT_APP_ORG_NAME}`
      : process.env.REACT_APP_ORG_NAME;
  }, [documentTitle]);
};

export default useCTDocumentTitle;
