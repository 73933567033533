const Consts = {
  EMPTY: '',
  VIDEO_STATUS: {
    UNPUBLISHED: 'UNPUBLISHED',
    APPROVED: 'APPROVED',
    PROCESSING: 'PROCESSING',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
    ERROR: 'ERROR',
    BLOCKED: 'BLOCKED',
  },
  TOKEN_TYPES: {
    REFRESH: 'refresh',
    ACCESS: 'access',
  },
  ROLES: {
    USER: 'user',
    PREMIUM_USER: 'premiumUser',
    ADMIN: 'admin',
    SUPER_ADMIN: 'superAdmin',
  },
  SNACKBAR_VARIANT: {
    ERROR: 'error',
    SUCCESS: 'success',
  },
};

export default Consts;
