import { useTheme } from '@material-ui/core';
import React from 'react';
import Routes from '../../consts/Routes';
import useAppContext from '../../hooks/useAppContext';
import useCTHistory from '../../hooks/useCTHistory';
import { hasAdminAccess } from '../../utils/utils';
import CTColoredButton from './CTColoredButton';

const AppModeButton = () => {
  const { isAdminMode, user } = useAppContext();
  const theme = useTheme();

  const history = useCTHistory();

  //  REWORK user in context to update all variables */

  return hasAdminAccess(user) ? (
    <CTColoredButton
      bgColor={theme.color.black}
      onClick={() => {
        if (isAdminMode) {
          history.push(Routes.HOMEPAGE);
        } else {
          history.push(Routes.REVIEW_VIDEOS);
        }
      }}
    >
      {isAdminMode ? 'User Mode' : 'Admin Mode'}
    </CTColoredButton>
  ) : null;
};

export default AppModeButton;
