import React from 'react';
import CTButton from './CTButton';

const RefreshButton = () => {
  const onClick = () => {
    window.location.reload();
  };
  return <CTButton onClick={onClick}>Refresh</CTButton>;
};

export default RefreshButton;
