import cookie from 'react-cookies';

export const saveCookie = (key, value) => {
  return cookie.save(key, value, {
    expires: new Date('2038-01-01'),
  });
};

export const loadCookie = key => {
  return cookie.load(key);
};

export const removeCookie = key => {
  return cookie.remove(key, {});
};
