import React from 'react';
import CTFormTag from './CTFormTag';

const CTFormMeta = ({ children, ctHalfWidth }) => {
  return (
    <CTFormTag ctHalfWidth={ctHalfWidth} noMarginBottom>
      {children}
    </CTFormTag>
  );
};

export default CTFormMeta;
