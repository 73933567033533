import { useTheme } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import AppModeButton from '../../components/button/AppModeButton';
import CTIconButton from '../../components/button/CTIconButton';
import JoinMembershipButton from '../../components/button/JoinMembershipButton';
import LoginButton from '../../components/button/LoginButton';
import RegisterButton from '../../components/button/RegisterButton';
import AnonymousMenu from '../../components/menu/AnonymousMenu';
import ProfileMenu from '../../components/menu/ProfileMenu';
import CTHeaderSearchForm from '../../components/misc/CTHeaderSearchForm';
import CTImage from '../../components/misc/CTImage';
import CTRedirectCard from '../../components/misc/CTRedirectCard';
import Routes from '../../consts/Routes';
import useAppContext from '../../hooks/useAppContext';
import { hasPremiumAccess } from '../../utils/utils';
import CTPageTopLoader from './CTPageTopLoader';

const CTHeader = ({ isNavOpen, setIsNavOpen }) => {
  const { user, isMobile, isMobileOrTablet } = useAppContext();
  const theme = useTheme();
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const onMobileSearchToggle = () => {
    setShowMobileSearch(!showMobileSearch);
  };

  return (
    <header>
      {showMobileSearch ? (
        <section className="header-content mobile-search">
          <section className="search">
            <CTHeaderSearchForm />
            <div className="clear-icon">
              <Clear onClick={onMobileSearchToggle} />
            </div>
          </section>
        </section>
      ) : (
        <section className="header-content">
          <section className="header-main">
            {isMobile &&
              (!isNavOpen ? (
                <CTIconButton
                  onClick={() => {
                    setIsNavOpen(true);
                  }}
                >
                  <MenuIcon />
                </CTIconButton>
              ) : (
                <CTIconButton>
                  <Clear />
                </CTIconButton>
              ))}
            <CTRedirectCard pushParams={Routes.HOMEPAGE}>
              <div className="logo-container">
                <div className="logo">
                  <CTImage
                    width={1}
                    height={1}
                    src={`${process.env.PUBLIC_URL}/logo192.png`}
                  ></CTImage>
                </div>
                <div>{process.env.REACT_APP_ORG_NAME}</div>
              </div>
            </CTRedirectCard>
          </section>
          {!isMobile && (
            <section className="search">
              <CTHeaderSearchForm />
            </section>
          )}
          <section className="action-buttons">
            {isMobile && <Search onClick={onMobileSearchToggle} />}
            {!user ? (
              <>
                {!isMobileOrTablet && <JoinMembershipButton />}
                <LoginButton />
                {isMobileOrTablet ? <AnonymousMenu /> : <RegisterButton />}
              </>
            ) : (
              <>
                {!isMobile && <AppModeButton />}

                {!isMobile && !hasPremiumAccess(user) && (
                  <JoinMembershipButton />
                )}
                <ProfileMenu />
              </>
            )}
          </section>
        </section>
      )}
      <CTPageTopLoader />
      <style jsx>
        {`
          header {
            position: sticky;
            top: 0;
            z-index: 1200;
            background: ${theme.palette.secondary.main};
          }
          .header-content {
            height: 100%;
            min-height: ${theme.header.height - 4}px;
            padding: ${isMobile ? '0px 2px' : '0px 16px'};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .header-main {
            display: flex;
            align-items: center;
          }
          .logo-container {
            font-size: ${theme.typography.title.fontSize}px;
            font-weight: ${theme.typography.title.fontWeight};
            display: flex;
          }
          .logo {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }
          .search {
            ${isMobile ? 'width: 100%;' : 'width: 40%;'}
            display: flex;
            align-items: center;
          }
          .action-buttons {
            display: flex;
            align-items: center;
          }
          .action-buttons > :global(*) {
            margin-left: ${isMobile ? '8px' : '16px'};
          }
          .clear-icon {
            margin-left: 8px;
          }
        `}
      </style>
    </header>
  );
};

export default CTHeader;
