import React from 'react';

// FUTURE: Have multiple context so updating one context only updates corresponding variables
const AppContext = React.createContext({
  isMobile: false,
  isTablet: false,
  isMobileOrTablet: false,
  categoryData: null,
  categoryError: false,
  isPageLoading: false,
  setIsPageLoading: () => {},
  isAdminMode: false,
  setIsAdminMode: () => {},
  user: null,
  setUser: () => {},
  setRefreshCategories: () => {},
});

export default AppContext;
