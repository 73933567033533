import React from 'react';
import { ReactComponent as Warning } from '../../assets/svg/warning.svg';
import Page from '../../components/page/Page';

const ErrorPage = ({
  title,
  subTitle,
  children,
  image = <Warning />,
  includeImage = true,
  documentTitle,
}) => {
  return (
    <div className="error-page-wrapper">
      <Page title={title} subTitle={subTitle} documentTitle={documentTitle}>
        {children}
        {includeImage && <div className="error-img-wrapper">{image}</div>}
      </Page>
      <style jsx>
        {`
          .error-page-wrapper {
            margin: 32px;
          }

          .error-img-wrapper {
            display: flex;
            justify-content: center;
          }

          .error-page-wrapper :global(.pageHeader) {
            max-width: none;
          }

          .error-img-wrapper :global(svg) {
            height: 30vh;
            width: 30vw;
          }
        `}
      </style>
    </div>
  );
};

export default ErrorPage;
