import { useEffect, useRef, useState } from 'react';
import { useLazyAxios } from 'use-axios-client';
import { decodeToken } from '../utils/utils';
import useAppContext from './useAppContext';
import APIs from '../consts/APIs';
import useCTHistory from './useCTHistory';
import Routes from '../consts/Routes';
import Consts from '../consts/Consts';
import useCTSnackBar from './useCTSnackBar';
import { loadCookie, saveCookie } from '../utils/cookieUtils';

// FUTURE: Custom error handling gets a func which returns boolean. If returns false, show snackbar
const useCTAxios = (config, onSuccess, onFailure) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueError } = useCTSnackBar();
  const history = useCTHistory();
  const headersRef = useRef(
    {
      ...config.headers,
      ...(config.isFormData && { 'Content-Type': 'multipart/form-data' }),
    } || {}
  );
  const callAPIArgs = useRef(null);
  const { user } = useAppContext();

  // https://use-axios-client.io/use-lazy-axios/
  const useAxiosParams = useLazyAxios({
    url: config.url,
    method: config.method || 'get',
    headers: headersRef.current,
    params: config.params,
    data: config.data,
    onUploadProgress: config.onUploadProgress,
  });
  const [refreshTokenCall, { data: tokenData, error: tokenError }] =
    useLazyAxios({
      url: APIs.getAPIUrl(APIs.REFRESH_TOKENS),
      method: 'post',
    });
  const [callAPI, ...restParams] = useAxiosParams;
  const { data, error, loading } = restParams[0];

  useEffect(() => {
    if (config.onLoad) {
      _callAPI();
    }
  }, []);

  useEffect(() => {
    if (error) {
      if (error.response && error.response.status === 401) {
        sendToLogin();
      } else if (
        !config.ignore403Handler &&
        error.response &&
        error.response.status === 403
      ) {
        history.push(Routes.ACCESS_DENIED);
      } else {
        if (config.showSnackBarOnError) {
          enqueueError();
        }
        if (onFailure) onFailure(error);
      }
    } else if (data) {
      if (onSuccess) onSuccess(data);
    }
  }, [data, error]);

  useEffect(() => {
    if (!loading && isLoading) {
      setIsLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (tokenError) {
      if (onFailure) onFailure(error);
      sendToLogin();
    } else if (tokenData) {
      const accessToken = tokenData.access.token;
      headersRef.current.Authorization = `Bearer ${accessToken}`;
      saveCookie(Consts.TOKEN_TYPES.ACCESS, accessToken);
      callAPI(callAPIArgs.current);
    }
  }, [tokenData, tokenError]);

  const sendToLogin = () => {
    history.push({ pathname: Routes.LOGIN, sessionExpired: true });
  };

  const _callAPI = data => {
    callAPIArgs.current = data;
    setIsLoading(true);
    if (user) {
      let toFetchAccessToken = true;
      const accessToken = loadCookie(Consts.TOKEN_TYPES.ACCESS);
      if (accessToken) {
        const { payload: accessPayload } = decodeToken(accessToken);
        if (accessPayload) {
          toFetchAccessToken = false;
          headersRef.current.Authorization = `Bearer ${accessToken}`;
          callAPI(callAPIArgs.current);
        }
      }
      if (toFetchAccessToken) {
        const refreshToken = loadCookie(Consts.TOKEN_TYPES.REFRESH);
        if (refreshToken) {
          const { payload: refreshPayload } = decodeToken(refreshToken);
          if (refreshPayload) {
            refreshTokenCall({ refreshToken });
          } else {
            sendToLogin();
          }
        }
      }
    } else {
      callAPI(callAPIArgs.current);
    }
  };

  const isCallMade = data || error || loading;
  return [
    _callAPI,
    { ...restParams[0], error: tokenError || error, loading: isLoading },
    isCallMade,
  ];
};

export default useCTAxios;
