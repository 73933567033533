import React from 'react';
import ErrorPage from './ErrorPage';
import { ReactComponent as UserLoginIcon } from '../../assets/svg/userLogin.svg';
import JoinMembershipButton from '../../components/button/JoinMembershipButton';

const UnAuthorizedPage = () => {
  return (
    <ErrorPage
      documentTitle="Access Denied"
      title="Please join Membership to view the requested resource."
      subTitle={<JoinMembershipButton />}
      image={<UserLoginIcon />}
      includeImage
    />
  );
};

export default UnAuthorizedPage;
