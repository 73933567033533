import React, { useEffect, useState } from 'react';
import APIs from '../consts/APIs';
import Consts from '../consts/Consts';
import { adminRoute } from '../consts/Routes';
import useAppContext from '../hooks/useAppContext';
import useCTAxios from '../hooks/useCTAxios';
import useCTLocation from '../hooks/useCTLocation';
import { loadCookie, removeCookie, saveCookie } from '../utils/cookieUtils';
import { decodeToken } from '../utils/utils';
import CTHeader from './header/CTHeader';
import Main from './main/Main';

// TODO: Reduce size of logos and ico files

// TODO: change png to jpeg for all files

// TODO: GET org details for about us page
const App = () => {
  // FUTURE: Handle network errors ?
  const { user, setUser, isMobile, setIsAdminMode, setRefreshCategories } =
    useAppContext();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false);

  const { pathname } = useCTLocation();

  const setUserDataInContext = () => {
    if (userDetails) {
      setUser(userDetails.user);
      if (userDetails.token) {
        saveCookie(Consts.TOKEN_TYPES.ACCESS, userDetails.token.access.token);
      }
    }
    setIsAuthCheckComplete(true);
  };

  const [getUserDetails, { data: userDetails }] = useCTAxios(
    { url: APIs.getAPIUrl(APIs.USER_DETAILS, user && user.id) },
    setUserDataInContext,
    setUserDataInContext
  );

  useEffect(() => {
    const refreshToken = loadCookie(Consts.TOKEN_TYPES.REFRESH);
    if (refreshToken) {
      const { payload } = decodeToken(refreshToken);
      if (payload) {
        setUser({ id: payload.id });
      } else {
        setIsAuthCheckComplete(true);
      }
    } else {
      setIsAuthCheckComplete(true);
    }
  }, []);

  useEffect(() => {
    if (user && user.id) {
      getUserDetails();
    }
  }, [user && user.id]);

  useEffect(() => {
    if (isAuthCheckComplete) {
      setRefreshCategories(true);
    }
  }, [isAuthCheckComplete]);

  useEffect(() => {
    if (!user && isAuthCheckComplete) {
      removeCookie(Consts.TOKEN_TYPES.REFRESH);
      removeCookie(Consts.TOKEN_TYPES.ACCESS);
    }
  }, [user]);

  useEffect(() => {
    if (isMobile) {
      setIsNavOpen(false);
    } else {
      setIsNavOpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (pathname && pathname.indexOf(adminRoute) > -1) {
      setIsAdminMode(true);
    } else {
      setIsAdminMode(false);
    }
  }, [pathname]);

  return (
    <>
      {isAuthCheckComplete && (
        <>
          <CTHeader isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
          <Main isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        </>
      )}
    </>
  );
};

export default App;
