import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import NotFoundPage from '../pages/error/NotFoundPage';
import UnAuthenticatedPage from '../pages/error/UnAuthenticatedPage';
import UnAuthorizedPage from '../pages/error/UnAuthorizedPage';

export const adminRoute = '/admin';
const getAdminRoute = route => {
  return `${adminRoute}${route}`;
};
const videoStreamRoute = '/video/:id';
const Routes = {
  HOMEPAGE: '/',
  MEMBERSHIP: '/membership',
  UPLOAD_USER_VIDEO: '/upload-user-video',
  VIDEO: videoStreamRoute,
  UNPUBLISHED_VIDEOS: getAdminRoute('/unpublished-videos'),
  REVIEW_VIDEOS: getAdminRoute('/review-videos'),
  CATEGORIES: '/categories',
  CATEGORY_VIDEOS: '/categories/:id',
  PLAYLISTS: '/playlists',
  PLAYLIST_VIDEOS: '/playlists/:id',
  ABOUT_US: '/about-us',
  SUPPORT_US: '/support-us',
  MY_VIDEOS: '/my-videos',
  ORG_VIDEOS: getAdminRoute('/org-videos'),
  USER_VIDEOS: getAdminRoute('/user-videos'),
  ADMIN_CATEGORIES: getAdminRoute('/categories'),
  UPLOAD_ORG_VIDEO: getAdminRoute('/upload-org-video'),
  ADMINS: getAdminRoute('/admins'),
  ADMIN_ADD_CATEGORY: getAdminRoute('/add-category'),
  ADMIN_UPDATE_CATEGORY: getAdminRoute('/update-category/:id'),
  ADMIN_VIDEO: getAdminRoute(videoStreamRoute),
  REGISTER: '/register',
  LOGIN: '/login',
  VERIFY_EMAIL: '/verify-email',
  CONFIRM_EMAIL: '/confirm-email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  MY_PROFILE: '/my-profile',
  MEMBERSHIP_SUCCESS: '/membership-success',
  MEMBERSHIP_FAILURE: '/membership-failure',
  SEARCH: '/search/:id',
  ADMIN_SEARCH: getAdminRoute('/search/:id'),
  TERMS_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  DONATE: '/donate',
  ACCESS_DENIED: '/access-denied',
  EDIT_VIDEO: '/video/edit/:id',
  ADMIN_EDIT_VIDEO: getAdminRoute('/video/edit/:id'),
};

const cachedPageMap = new Map();

export const getLazyPage = path => {
  if (cachedPageMap.has(path)) return cachedPageMap.get(path);

  const LazyPage = lazy(() => import(`../pages/${path}`));
  cachedPageMap.set(path, LazyPage);
  return LazyPage;
};
export const getPage = async path => {
  await import(`../pages/${path}`);
};

export const getRoutes = pages => {
  return pages.map(page => {
    const LazyPage = getLazyPage(page.path);
    return (
      <Route
        key={page.url}
        exact
        path={page.url}
        render={() => {
          return page.redirect ? (
            <Redirect to={page.redirect} />
          ) : page.disabled ? (
            <NotFoundPage />
          ) : page.unAuthenticated ? (
            <UnAuthenticatedPage />
          ) : page.unAuthorized ? (
            <UnAuthorizedPage />
          ) : (
            <LazyPage {...page.props} />
          );
        }}
      />
    );
  });
};

export const updateRouteWithName = (route, name) => {
  return route.replace(':name', name);
};

export default Routes;
