import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import APIs from '../consts/APIs';
import useCTAxios from '../hooks/useCTAxios';
import App from './App';
import AppContext from './context/AppContext';

const AppContextWrapper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isMobileOrTablet = isMobile || isTablet;
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [user, setUser] = useState(null);
  const [refreshCategories, setRefreshCategories] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [getCategories, { data: categoryData, error: categoryError }] =
    useCTAxios({
      url: APIs.getAPIUrl(APIs.CATEGORIES),
    });

  useEffect(() => {
    if (refreshCategories) {
      getCategories();
      setRefreshCategories(false);
    }
  }, [refreshCategories]);

  return (
    <>
      <AppContext.Provider
        value={{
          isMobile: isMobile,
          isTablet: isTablet,
          isMobileOrTablet: isMobileOrTablet,
          categoryData: categoryData,
          categoryError: categoryError,
          isPageLoading: isPageLoading,
          setIsPageLoading: setIsPageLoading,
          isAdminMode: isAdminMode,
          setIsAdminMode: setIsAdminMode,
          user: user,
          setUser: setUser,
          setRefreshCategories: setRefreshCategories,
        }}
      >
        <App />
      </AppContext.Provider>
    </>
  );
};

export default AppContextWrapper;
