import React, { useContext } from 'react';
import AppContext from '../../app/context/AppContext';
import Consts from '../../consts/Consts';
import useCTDocumentTitle from '../../hooks/useCTDocumentTitle';
import PageHeader from './PageHeader';

const Page = ({
  title,
  subTitle,
  children,
  className = Consts.EMPTY,
  textAlign,
  reduceMarginBottom,
  documentTitle,
  paddingVertical = 32,
}) => {
  useCTDocumentTitle(documentTitle || title);
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={`page ${isMobile ? 'page-mobile' : Consts.EMPTY}${className} `}
    >
      {title && (
        <PageHeader
          title={title}
          subTitle={subTitle}
          reduceMarginBottom={reduceMarginBottom}
        />
      )}

      <>{children}</>

      <style jsx>
        {`
          .page {
            flex-basis: 100%;
            padding: ${paddingVertical}px 32px;
            margin: 0 auto;
            box-sizing: border-box;
            height: 100%;
            ${textAlign ? `text-align: ${textAlign}` : Consts.EMPTY}
          }
          .page-mobile {
            padding: 16px;
          }
        `}
      </style>
    </div>
  );
};

export default Page;
