import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import useCTHistory from '../../hooks/useCTHistory';

const CTButton = ({
  variant = 'contained',
  color = 'primary',
  children,
  onClick,
  pushParams,
  type = 'button',
  disabled = false,
  loading = false,
  startIcon,
}) => {
  const history = useCTHistory();
  const handleOnClick = () => {
    if (onClick) onClick();
    if (pushParams) {
      history.push(pushParams);
    }
  };
  return (
    <Button
      variant={variant}
      color={color}
      type={type}
      disabled={disabled || loading}
      onClick={handleOnClick}
      startIcon={startIcon}
    >
      {loading ? <CircularProgress color="inherit" size={24} /> : children}
      <style jsx>
        {`
          :global(.MuiButton-label) {
            white-space: nowrap;
          }
        `}
      </style>
    </Button>
  );
};

export default CTButton;
