import React from 'react';
import RefreshButton from '../../components/button/RefreshButton';
import ErrorPage from './ErrorPage';

const WeDownPage = () => {
  return (
    <ErrorPage
      title="Something went wrong. Please Try again"
      subTitle={<RefreshButton />}
      includeImage
    ></ErrorPage>
  );
};

export default WeDownPage;
