import { createTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ErrorBoundary from '../components/error/ErrorBoundary';
import CTSnackBarProvider from '../components/misc/CTSnackBarProvider';
import AppContextWrapper from './AppContextWrapper';
import theme from './context/Theme';

const AppWrapper = () => {
  const customTheme = createTheme(theme);
  return (
    <ErrorBoundary>
      <ThemeProvider theme={customTheme}>
        <CTSnackBarProvider>
          <AppContextWrapper />
        </CTSnackBarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default AppWrapper;
