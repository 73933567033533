import React from 'react';
import Consts from '../../consts/Consts';
import parse from 'html-react-parser';

// Contains page title, subtitle at the top of a page
const PageHeader = ({
  title,
  subTitle,
  reduceMarginBottom = false,
  hasMarginTop = false,
}) => {
  const renderSubTitle = () => {
    if (typeof subTitle === 'string') {
      return parse(subTitle);
    } else {
      return subTitle;
    }
  };
  return (
    <section className="pageHeader">
      <h1>{title}</h1>
      {subTitle && renderSubTitle()}
      <style jsx>{`
        section {
          margin: 0px auto 32px;
          ${hasMarginTop ? 'margin-top: 32px;' : Consts.EMPTY}
          ${reduceMarginBottom ? 'margin-bottom: 16px;' : Consts.EMPTY}
          max-width: 720px;
          box-sizing: border-box;
        }
        h1,
        h3 {
          margin: 0;
        }

        h3 {
          margin-top: 8px;
        }
      `}</style>
    </section>
  );
};

export default PageHeader;
