import { Avatar, useTheme } from '@material-ui/core';
import React from 'react';

const CTAvatar = ({ name, src, variant = 'medium' }) => {
  const theme = useTheme();
  return (
    <div className={`avatar-container ${variant}`}>
      <Avatar alt={name} src={src}>
        {name && name[0].toUpperCase()}
      </Avatar>
      <style jsx>
        {`
          .avatar-container :global(.MuiAvatar-root) {
            background-color: ${theme.color.primary};
          }
          .ultra-large :global(.MuiAvatar-root) {
            width: 80px;
            height: 80px;
          }
          .extra-large :global(.MuiAvatar-root) {
            width: 40px;
            height: 40px;
          }
          .large :global(.MuiAvatar-root) {
            width: 32px;
            height: 32px;
          }
          .medium :global(.MuiAvatar-root) {
            width: 28px;
            height: 28px;
            font-size: 18px;
          }
          .small :global(.MuiAvatar-root) {
            width: 24px;
            height: 24px;

            font-size: 16px;
          }
        `}
      </style>
    </div>
  );
};

export default CTAvatar;
