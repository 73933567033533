import { useSnackbar } from 'notistack';
import Consts from '../consts/Consts';

const useCTSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueError = (
    message = 'Something went wrong. Try again.',
    options = {}
  ) => {
    enqueueSnackbar(message, {
      variant: Consts.SNACKBAR_VARIANT.ERROR,
      ...options,
    });
  };
  return { enqueueSnackbar, enqueueError };
};

export default useCTSnackBar;
