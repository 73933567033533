const white = '#FFFFFF';
const black = '#000000';
const primary = '#1F82C3';

const titleFontSize = 20;
const subtitle1FontSize = 12;

const bold = 500;

const theme = {
  typography: {
    title: {
      fontSize: titleFontSize,
      fontWeight: bold,
    },
    subtitle1: {
      fontSize: subtitle1FontSize,
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: white,
    },
    black: {
      main: black,
    },
  },
  color: {
    primary: primary,
    black: black,
    white: white,
    errorRed: '#e62716',
    placeholderGrey: '#999',
    borderGrey: 'rgba(0, 0, 0, 0.23)',
    darkGrey: '#333',
    textGrey: '#767676',
    green: 'green',
    orange: 'orange',
    bodyBg: '#fafafa',
  },
  header: {
    height: 60,
  },
  sideBar: {
    width: 90,
  },
  videoStatus: {
    width: 160,
  },
  customFontSizes: {
    title: `${titleFontSize}px`,
  },
};

export default theme;
