import { ClickAwayListener, Paper, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useCTLocation from '../../hooks/useCTLocation';

const MenuComponent = ({ menuButton, children }) => {
  const theme = useTheme();
  const { pathname } = useCTLocation();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    handleClose();
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = event => {
    if (open) {
      if (event) {
        event.preventDefault();
      }
      setOpen(false);
    }
  };

  return (
    <div className="menu">
      <div onClick={handleToggle} className="menu-button">
        {menuButton}
      </div>
      {open && (
        <div className="menu-content">
          <ClickAwayListener onClickAway={handleClose}>
            <Paper>{children}</Paper>
          </ClickAwayListener>
        </div>
      )}
      <style jsx>
        {`
          .menu-content {
            background-color: ${theme.color.white};
            position: absolute;
            right: 8px;
            margin-top: 8px;
          }
          .menu-content :global(.MuiPaper-root) {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 16px 16px 8px;
          }
          .menu-content :global(.MuiPaper-root) > :global(*) {
            margin-bottom: 8px;
          }
          .menu-button {
            position: relative;
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

export default MenuComponent;
