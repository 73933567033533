import { TextField } from 'formik-material-ui';
import React from 'react';
import CTFormItem from './CTFormItem';

const CTTextField = ({ variant = 'outlined', ...props }) => {
  return (
    <CTFormItem
      component={TextField}
      {...props}
      fullWidth={true}
      variant={variant}
    />
  );
};

export default CTTextField;
