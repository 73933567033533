import React, { Component } from 'react';
import WeDownPage from '../../pages/error/WeDownPage';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      data: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      data: info,
    });
    console.error('Error', error);
    console.error('info', info);
  }

  render() {
    if (this.state.hasError) {
      return <WeDownPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
