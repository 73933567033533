import { Field } from 'formik';
import React from 'react';
import useCTFormikState from '../../../hooks/useCTFormikState';
import CTFormErrorText from './CTFormErrorText';
import CTFormTag from './CTFormTag';

const CTFormItem = ({
  initialValue,
  forceShowError,
  name,
  label,
  isOptional = false,
  ctHalfWidth,
  ctState,
  ctSetState,
  noMarginBottom,
  ...props
}) => {
  const customLabel = isOptional ? `${label} (optional)` : label;

  useCTFormikState(name, ctState, ctSetState);
  return (
    <CTFormTag ctHalfWidth={ctHalfWidth} noMarginBottom={noMarginBottom}>
      <Field name={name} label={customLabel} {...props} />
      {forceShowError && <CTFormErrorText name={name} />}
      <style jsx>
        {`
          .ct-form-item {
            margin-bottom: 16px;
          }
          .full-width {
            width: 100%;
          }
          .half-width {
            width: calc(50% - 8px);
          }
        `}
      </style>
    </CTFormTag>
  );
};

export default CTFormItem;
