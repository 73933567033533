import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import useAppContext from '../../hooks/useAppContext';

const CTPageTopLoader = () => {
  const [progress, setProgress] = useState(0);
  const { isPageLoading } = useAppContext();
  const timer = useRef(null);

  useEffect(() => {
    if (isPageLoading) {
      const timerId = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 70) {
            return oldProgress;
          }
          const diff = Math.random() * 40;
          return Math.min(oldProgress + diff, 70);
        });
      }, 100);
      timer.current = timerId;
    } else {
      clearInterval(timer.current);
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
      }, 500);
    }
  }, [isPageLoading]);

  return (
    <div>
      <LinearProgress variant="determinate" value={progress} />
      <style jsx>
        {`
          div {
            z-index: 2000;
          }
          div :global(.MuiLinearProgress-root) {
            background-color: transparent;
          }
          div
            :global(.MuiLinearProgress-root[aria-valuenow='0']
              > .MuiLinearProgress-bar) {
            transition: none;
          }
        `}
      </style>
    </div>
  );
};

export default CTPageTopLoader;
