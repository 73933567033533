import { useHistory } from 'react-router-dom';
import { getPage } from '../consts/Routes';
import { getHistoryMeta } from '../utils/utils';
import useAppContext from './useAppContext';
import usePages from './usePages';

const useCTHistory = () => {
  const pages = usePages();
  const { push, ...other } = useHistory();
  const { setIsPageLoading } = useAppContext();
  const pushWithLoader = (params, id) => {
    const meta = getHistoryMeta(params, id);
    const nextPage = pages.filter(page => page.url === meta.route);
    if (nextPage && nextPage.length === 1) {
      setIsPageLoading(true);
      getPage(nextPage[0].path).then(() => {
        // FUTURE: move this to page useeffect []
        // FUTURE: set nextPAge instead of boolean to cancel subsequent route changes
        setTimeout(() => {
          setIsPageLoading(false);
          push(meta.params);
        }, 250);
      });
    }
  };
  return { ...other, push: pushWithLoader };
};

export default useCTHistory;
