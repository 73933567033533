import { useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React from 'react';

const CTSnackBarProvider = ({ isMobile, children }) => {
  const theme = useTheme();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      dense={isMobile}
      preventDuplicate
    >
      {children}
      <style jsx>
        {`
          :global(.SnackbarItem-variantError-22) {
            background-color: ${theme.color.errorRed};
          }
        `}
      </style>
    </SnackbarProvider>
  );
};

export default CTSnackBarProvider;
