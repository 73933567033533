import { useEffect, useState } from 'react';
import useAppContext from './useAppContext';
import Pages from '../consts/Pages';

const usePages = () => {
  const { isAdminMode, user } = useAppContext();
  const [pages, setPages] = useState(Pages(user, isAdminMode));
  useEffect(() => {
    setPages(Pages(user, isAdminMode));
  }, [isAdminMode, user, user && user.role]);

  return pages;
};

export default usePages;
