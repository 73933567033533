import { useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Consts from '../../consts/Consts';
import Routes from '../../consts/Routes';
import useCTHistory from '../../hooks/useCTHistory';
import useCTLocation from '../../hooks/useCTLocation';
import AppContext from '../context/AppContext';
const CTSideBarItem = ({ url, label, icon, setIsNavOpen }) => {
  const theme = useTheme();
  const [isSelected, setIsSelected] = useState(false);
  const location = useCTLocation();
  const { isMobile } = useContext(AppContext);
  const history = useCTHistory();

  useEffect(() => {
    setIsSelected(
      url === Routes.HOMEPAGE
        ? location.pathname === Routes.HOMEPAGE
        : location.pathname.startsWith(url)
    );
  }, [location.pathname, url]);

  // FUTURE: change to ctredirect
  const onClick = () => {
    history.push(url);
  };

  return (
    <>
      <div
        className={`is-nav navItem ${isSelected ? 'selected' : Consts.EMPTY}`}
        onClick={() => {
          if (isMobile) {
            setIsNavOpen(false);
          }
        }}
      >
        <div href={url} className="is-nav" onClick={onClick}>
          {icon}
          <p>{label}</p>
        </div>
      </div>

      <style jsx>
        {`
          .navItem .is-nav {
            padding: 8px 0px;
            font-size: ${theme.typography.subtitle1.fontSize}px;
            border-left: 5px solid transparent;
            display: block;
            text-decoration: none;
            color: ${theme.color.black};
            text-align: center;
            cursor: pointer;
            line-height: 16px;
          }

          .navItem.selected .is-nav {
            font-weight: bold;
            color: ${theme.palette.primary.main};
          }

          .navItem.disabled .is-nav {
            pointer-events: none;
            cursor: default;
            opacity: 0.4;
          }
          p {
            margin: 0px;
          }
        `}
      </style>
    </>
  );
};
export default CTSideBarItem;
