import IconButton from '@material-ui/core/IconButton';
import React from 'react';

const CTIconButton = ({ color = 'primary', children, onClick }) => {
  return (
    <IconButton color={color} onClick={onClick}>
      {children}
    </IconButton>
  );
};

export default CTIconButton;
