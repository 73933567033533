import { MoreVert } from '@material-ui/icons';
import React from 'react';
import JoinMembershipButton from '../button/JoinMembershipButton';
import RegisterButton from '../button/RegisterButton';
import MenuComponent from './MenuComponent';

const AnonymousMenu = () => {
  return (
    <div className="anonymous-menu">
      <MenuComponent menuButton={<MoreVert />}>
        <div>
          <RegisterButton />
        </div>
        <div>
          <JoinMembershipButton />
        </div>
      </MenuComponent>
    </div>
  );
};

export default AnonymousMenu;
