import { useTheme } from '@material-ui/core';
import React from 'react';
import Routes from '../../consts/Routes';
import CTColoredButton from './CTColoredButton';

const JoinMembershipButton = () => {
  const theme = useTheme();
  return (
    <CTColoredButton bgColor={theme.color.black} pushParams={Routes.MEMBERSHIP}>
      Membership
    </CTColoredButton>
  );
};

export default JoinMembershipButton;
